// @flow
import React from 'react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { FieldArrayGrid, FieldArrayGridConfigItemContext } from '@src/formik-utils/FieldArrayGrid';
import { useSPDefaultCommitDetailsContext } from '@streaming-projects/default-commit-details/context/SPDefaultCommitDetailsContext';

import { SPCreditAndBurstsConfigurationItem } from './SPCreditAndBurstsConfigurationItem';
import { SP_CNBC_BACKEND_FIELDNAMES } from './enums';
import { SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG } from './config';

export const SPCreditsAndBurstsContainer = ({
  readOnlyMode,
  createMutationToUse,
  updateMutationToUse,
  deleteMutationToUse,
  getContextData = useSPDefaultCommitDetailsContext,
}) => {
  const {
    commit_cnbc_inputs: commitCnbcInputs = [],
    calendar_years: calendarYears,
    version,
  } = getContextData();

  // todo::SP Change prop drilling to Context in this file and all other files
  const readOnlyModeFromProps = readOnlyMode;

  const pathToFollowInValues = [SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_JSON];

  const areAllTheYearsTaken = calendarYears.every((year) =>
    commitCnbcInputs.some((cnbcInputObj) => cnbcInputObj.year_number === year.year_number)
  );

  return (
    <>
      <PaddedAndRaisedSegment disabled={false}>
        <FieldArrayGrid
          addRowButtonDisabled={areAllTheYearsTaken}
          columnsConfiguration={SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG}
          disabled={readOnlyMode}
          gridName="Credits And Bursts"
          pathToFollowInValues={pathToFollowInValues}
        >
          {(arrayHelpers, row, isCurrentlyBeingEdited, index, allRows, readOnlyMode) => (
            <FieldArrayGridConfigItemContext.Provider
              value={{
                allRows,
                arrayHelpers,
                columnsConfiguration: SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG,
                index,
                isCurrentlyBeingEdited,
                pathToFollowInValues: pathToFollowInValues,
                rowValues: row,
                readOnlyMode,
              }}
            >
              <SPCreditAndBurstsConfigurationItem
                calendarYears={calendarYears}
                commitCnbcInputs={commitCnbcInputs}
                createMutationToUse={createMutationToUse}
                deleteMutationToUse={deleteMutationToUse}
                readOnlyMode={readOnlyModeFromProps}
                updateMutationToUse={updateMutationToUse}
                version={version}
              />
            </FieldArrayGridConfigItemContext.Provider>
          )}
        </FieldArrayGrid>
      </PaddedAndRaisedSegment>
    </>
  );
};
