// @flow
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridUpdateButton,
} from '@src/formik-utils/FieldArrayGrid';
import { DEFAULT_VALUE_FOR_DB_ROW_ID, ROW_ID } from '@src/constants';

import { SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG } from '../config';

export const SPUpdateButtonContainer = ({ createMutationToUse, updateMutationToUse, version }) => {
  const { orgId, commitId } = useParams();
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);
  const [updateCNBCConfig] = updateMutationToUse();
  const [createCNBCConfig] = createMutationToUse();
  const { id, isRowOpenedForEditing, isRowFrozen, ...payload } = rowValues;

  const updateFuncParams = {
    payload: {
      ...payload,
      commit_version: version,
    },
    cnbcId: rowValues[ROW_ID],
    orgId,
    commitId,
  };

  return (
    <FieldArrayGridUpdateButton
      columnsConfig={SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG}
      errorMessage="There was an error in updating the Credits and Bursts configuration, please contact #cloud-commitment-estimator channel!"
      updateFunc={
        rowValues[ROW_ID] === DEFAULT_VALUE_FOR_DB_ROW_ID ? createCNBCConfig : updateCNBCConfig
      }
      updateFuncParams={updateFuncParams}
    />
  );
};
