// @flow
import React from 'react';
import { ShowLoaderIfAnyQueryIsPending } from '@presentational/ShowLoaderIfAnyQueryIsPending';
import { UserContextProvider } from '@src/contexts/UserContextProvider';
import { SPOrgContextProvider } from '@streaming-projects/orgs/contexts/SPOrgContextProvider';
import { SPUserCommitDetailsContextProvider } from '@streaming-projects/user-commit-details/contexts/SPUserCommitDetailsContextProvider';
import { SPUserCommitDetailsTopLevelContainerForm } from '@streaming-projects/user-commit-details/SPUserCommitDetailsTopLevelContainerForm';
import { SPRateCardMetaContextProvider } from '@streaming-projects/orgs/contexts/SPRateCardMetaContextProvider';

import SPUserCommitDetailsBreadcrumbs from './breadcrumbs/SPUserCommitDetailsBreadcrumbs';
import { SPUserCommitMetadataContextProvider } from './contexts/SPUserCommitMetadataContextProvider';

export const SPUserCommitDetailsTopLevelContainer = () => {
  return (
    <>
      <ShowLoaderIfAnyQueryIsPending />
      <UserContextProvider>
        <SPOrgContextProvider>
          <SPUserCommitDetailsContextProvider>
            <SPUserCommitMetadataContextProvider>
              <SPRateCardMetaContextProvider>
                <SPUserCommitDetailsBreadcrumbs />
                <SPUserCommitDetailsTopLevelContainerForm />
              </SPRateCardMetaContextProvider>
            </SPUserCommitMetadataContextProvider>
          </SPUserCommitDetailsContextProvider>
        </SPOrgContextProvider>
      </UserContextProvider>
    </>
  );
};
