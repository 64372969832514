// @flow
import { useHistory, useParams } from 'react-router-dom';
import React from 'react';
import { getLinkForOrganizationsPage } from '@streaming-projects/links';
import { getDeleteWithWarningHeaderAndBody } from '@src/common-utils/utils';
import { useDeleteSPUserCommitMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError, toastSuccess } from '@presentational/notifications/utils';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';

import { useSPUserCommitDetailsContext } from './contexts/SPUserCommitDetailsContext';

export const SPUserCommitDeleteWarningModal = ({ isOpen, onClose, commitName }) => {
  const { version } = useSPUserCommitDetailsContext();
  const [deleteCommit] = useDeleteSPUserCommitMutation();
  const { orgId, commitId } = useParams();
  const { push } = useHistory();
  const { header, headerBody } = getDeleteWithWarningHeaderAndBody('User Commit', commitName);

  const onClickHandlerForOK = async () => {
    const { error } = await deleteCommit({
      commitId,
      orgId,
      payload: {
        version,
      },
    });
    if (error) {
      toastError(error);
    } else {
      toastSuccess(`User Commit ${commitName} delete was successful!`);
      push(getLinkForOrganizationsPage(orgId));
    }
  };

  return (
    <ConfirmModal
      body={headerBody}
      header={header}
      isOpen={isOpen}
      onClickHandlerForCancel={onClose}
      onClickHandlerForOK={onClickHandlerForOK}
    />
  );
};
