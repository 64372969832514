// @flow
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { StyledGridRow } from '@src/common-utils/styledComponents';
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { useUpdateSPUserCommitDetailsMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { Spacer } from '@presentational/spacing/Spacer';
import { SP_USER_COMMIT_GENERIC_DETAILS_CONFIG } from '@streaming-projects/user-commit-details/config';
import { SPUserCommitDetailsContext } from '@streaming-projects/user-commit-details/contexts/SPUserCommitDetailsContext';

import { SPUserCommitEndDateContainer } from './SPUserCommitEndDateContainer';
import { SPUserCommitStartDateContainer } from './SPUserCommitStartDateContainer';
import { SPUserCommitDealTypeContainer } from './SPUserCommitDealTypeContainer';
import { SPUserCommitMarketplaceContainer } from './SPUserCommitMarketplaceContainer';
import { SPUserCommitPaymentScheduleContainer } from './SPUserCommitPaymentScheduleContainer';
import { SPUserCommitRateCardContainer } from './SPUserCommitRateCardContainer';
import { SPUserCommitSupportTierContainer } from './SPUserCommitSupportTierContainer';
import { SPUserCommitCNBCContainer } from './SPUserCommitCNBCContainer';
import { SPUserCommitNameContainer } from './SPUserCommitNameContainer';

export const SPUserCommitGenericDetailsContainer = () => {
  const { orgId, commitId } = useParams();
  const { version } = useContext(SPUserCommitDetailsContext);
  const [updateUserCommitDetails] = useUpdateSPUserCommitDetailsMutation();
  const fieldsConfig = SP_USER_COMMIT_GENERIC_DETAILS_CONFIG;

  return (
    <>
      <PaddedAndRaisedSegment>
        <Grid>
          <StyledGridRow columns={3}>
            <Grid.Column>
              <SPUserCommitNameContainer />
            </Grid.Column>
            <Grid.Column>
              <SPUserCommitStartDateContainer />
            </Grid.Column>
            <Grid.Column>
              <SPUserCommitEndDateContainer />
            </Grid.Column>
          </StyledGridRow>
          <StyledGridRow columns={3}>
            <Grid.Column>
              <SPUserCommitDealTypeContainer />
            </Grid.Column>
            <Grid.Column>
              <SPUserCommitMarketplaceContainer />
            </Grid.Column>
            <Grid.Column>
              <SPUserCommitPaymentScheduleContainer />
            </Grid.Column>
          </StyledGridRow>
          <StyledGridRow columns={3}>
            <Grid.Column>
              <SPUserCommitRateCardContainer />
            </Grid.Column>
            <Grid.Column>
              <SPUserCommitSupportTierContainer />
            </Grid.Column>
            <Grid.Column>
              <SPUserCommitCNBCContainer />
            </Grid.Column>
          </StyledGridRow>
        </Grid>

        <Spacer y={40} />

        <GenericSaveResetButtons
          fieldsConfig={fieldsConfig}
          onSaveHandler={async (payload) => {
            await updateUserCommitDetails({
              orgId,
              commitId,
              payload: {
                commit_details: payload,
                version: version,
              },
            });
          }}
        />
      </PaddedAndRaisedSegment>
    </>
  );
};
