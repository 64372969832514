// @flow
import React, { useContext } from 'react';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SelectField } from '@src/formik-utils/FormFields';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SP_USER_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/user-commit-details/config';
import { fetchSingleSelectedRateCardOptionFromGivenRCs } from '@src/common-utils/utils';
import { useRateCardMetaContext } from '@streaming-projects/orgs/contexts/SPRateCardMetaContextProvider';

export const SPUserCommitRateCardContainer = () => {
  const { org } = useContext(SPOrgContext);
  const orgRateCard = org.rate_card;
  const rcVersions = useRateCardMetaContext()?.rate_cards;
  const selectedRateCard = fetchSingleSelectedRateCardOptionFromGivenRCs(orgRateCard, rcVersions);
  const { backendFieldName, displayName, disabled, tooltip } =
    SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(BACKEND_FIELDNAMES.RATE_CARD);

  return (
    <SelectField
      disabled={disabled}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      options={selectedRateCard}
      tooltip={tooltip}
    />
  );
};
