// @flow
import React, { useContext } from 'react';
import { getDropdownOptionsFromArray } from '@src/common-utils/utils';
import { SP_USER_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/user-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SelectField } from '@src/formik-utils/FormFields';

import { SPUserCommitMetadataContext } from '../contexts/SPUserCommitMetadataContext';

export const SPUserCommitSupportTierContainer = () => {
  const spUserCommitMetadata = useContext(SPUserCommitMetadataContext);
  const supportTiers = spUserCommitMetadata?.commit_config?.support_tier ?? [];
  const supportTiersOptions = getDropdownOptionsFromArray(supportTiers);
  const { backendFieldName, displayName } = SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.SUPPORT_TIER
  );

  return (
    <SelectField
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      options={supportTiersOptions}
    />
  );
};
