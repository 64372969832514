// @flow
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  SP_SUMMARY_GRID_FIELDNAMES,
  SP_SUMMARY_GRID_HEADERS,
} from '@streaming-projects/orgs/enums';
import { SPNameRenderer } from '@streaming-projects/orgs/org-top-level/sp-summary/SPNameRenderer';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { DataGrid } from '@components/views/grid-utils/DataGrid';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { Spacer } from '@presentational/spacing/Spacer';
import { useUpdateSPUserCommitsSPSelectionMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { toastError } from '@presentational/notifications/utils';
import { EnabledCellRenderer } from '@components/views/grid-utils/renderers';
import { getSortedFieldValuesFromArrayOfObjects } from '@src/common-utils/utils';
import { ResetFormButton } from '@presentational/buttons/ResetFormButton';
import { SaveFormButton } from '@presentational/buttons/SaveFormButton';
import { isEqual } from 'lodash';

import { SPUserCommitDetailsContext } from '../contexts/SPUserCommitDetailsContext';

// CTODO::SP - Break this Component into Smaller Parts
export const SPUserCommitSPSelectionContainer = ({ spUserCommitSPSelectionData }) => {
  const { orgId, commitId } = useParams();
  const {
    version,
    sorted_list_of_initial_selected_sp_ids: initialSelectedListOfSPsForThisUserCommit,
  } = useContext(SPUserCommitDetailsContext);
  const [updateSPUserCommitsSPSelection] = useUpdateSPUserCommitsSPSelectionMutation();

  const fullListOfOrgSPs = spUserCommitSPSelectionData?.sp_details ?? [];

  const [selectedListOfSPsForThisUserCommit, setSelectedListOfSPsForThisUserCommit] = useState(
    initialSelectedListOfSPsForThisUserCommit
  );

  // CTODO::SP - This is a Hack to Reset the Grid, Need to find a Better Way
  const [gridKey, setGridKey] = useState(0);

  const columnDefs = [
    {
      field: SP_SUMMARY_GRID_FIELDNAMES.NAME,
      headerName: SP_SUMMARY_GRID_HEADERS.NAME,
      cellRenderer: SPNameRenderer,
      sort: 'asc',
    },
    {
      field: SP_SUMMARY_GRID_FIELDNAMES.IS_ENABLED,
      headerName: SP_SUMMARY_GRID_HEADERS.IS_ENABLED_LABEL,
      cellRenderer: EnabledCellRenderer,
    },
    {
      field: SP_SUMMARY_GRID_FIELDNAMES.ID,
      sortable: false,
      filter: false,
      checkboxSelection: true,
      headerCheckboxSelection: true,
      valueFormatter: () => '', // To not Display ID's in the Grid
      headerName: 'Select',
    },
  ];

  const nodeSelectionFunc = (node) =>
    initialSelectedListOfSPsForThisUserCommit.includes(node.data[SP_SUMMARY_GRID_FIELDNAMES.ID]);

  const handleSelectionChanged = ({ api }) => {
    const selectedRows = api.getSelectedRows();
    const sortedArrayOfSelectedSPIds = getSortedFieldValuesFromArrayOfObjects(selectedRows, 'id');

    setSelectedListOfSPsForThisUserCommit(sortedArrayOfSelectedSPIds);
  };

  const onResetHandler = () => {
    setSelectedListOfSPsForThisUserCommit(initialSelectedListOfSPsForThisUserCommit);
    setGridKey((prevKey) => prevKey + 1);
  };

  const onSaveHandler = async () => {
    const { error } = await updateSPUserCommitsSPSelection({
      orgId,
      commitId,
      payload: {
        sp_ids: selectedListOfSPsForThisUserCommit,
        version: version,
      },
    });
    if (error) {
      toastError(error);
    }
  };

  const shouldResetOrSaveBeDisabled = isEqual(
    selectedListOfSPsForThisUserCommit,
    initialSelectedListOfSPsForThisUserCommit
  );

  return (
    <StyledContainer>
      <PaddedAndRaisedSegment>
        <DataGrid
          columnDefs={columnDefs}
          downloadAllCols={false}
          inputDataSource={fullListOfOrgSPs}
          key={gridKey}
          noDataMessage="No Streaming Projects Configured"
          nodeSelectionFunc={nodeSelectionFunc}
          onSelectionChanged={handleSelectionChanged}
          rowKey={SP_SUMMARY_GRID_FIELDNAMES.ID}
          rowMultiSelectWithClick={true}
          rowSelection="multiple"
          selectedRowIds={selectedListOfSPsForThisUserCommit}
          sizeColumnsToFitInitially={true}
        />

        <Spacer y={40} />

        <>
          <ResetFormButton disabled={shouldResetOrSaveBeDisabled} onClick={onResetHandler} />
          <Spacer x={1} />
          <SaveFormButton disabled={shouldResetOrSaveBeDisabled} onClick={onSaveHandler} />
        </>
      </PaddedAndRaisedSegment>
    </StyledContainer>
  );
};
