// @flow
import React, { useCallback } from 'react';
import {
  useGetSPStreamingProjectPricingStatusViewQuery,
  useSubmitRecalcRequestForStreamingProjectMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SPStreamingProjectPricingMonthlySpendSummaryContainerWrapper } from '@streaming-projects/sp-page/accordions-container/SPStreamingProjectPricingMonthlySpendSummaryContainerWrapper';
import SPAsyncCalcWrapper from '@components/common/pricing-summary-wrapper/SPAsyncCalcWrapper';

export const SPStreamingProjectPricingSummaryContainer = () => {
  const pricingStatusFetchingFunction = useCallback(
    useGetSPStreamingProjectPricingStatusViewQuery,
    []
  );

  return (
    <SPAsyncCalcWrapper
      pricingStatusFetchingFunction={pricingStatusFetchingFunction}
      useRecalcRequestMutation={useSubmitRecalcRequestForStreamingProjectMutation}
    >
      <SPStreamingProjectPricingMonthlySpendSummaryContainerWrapper disabled={false} />
    </SPAsyncCalcWrapper>
  );
};
