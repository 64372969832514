// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import { useGetOrgSPDetailsQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';

import { SPUserCommitSPSelectionContainer } from './SPUserCommitSPSelectionContainer';

export const SPUserCommitSPSelectionContainerWrapper = () => {
  const params = useParams();

  const dataFetchingArgs = useMemo(
    () => [{ ...params }, { skip: shouldSkipDataFetching(params) }],
    [params]
  );
  const dataFetchingFunction = useCallback(useGetOrgSPDetailsQuery, []);

  return (
    <DataFetcherContainer
      dataFetchingArgs={dataFetchingArgs}
      dataFetchingFunction={dataFetchingFunction}
    >
      {(data) => <SPUserCommitSPSelectionContainer spUserCommitSPSelectionData={data} />}
    </DataFetcherContainer>
  );
};
