// @flow
import React, { useContext } from 'react';
import { ROW_ID } from '@src/constants';
import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridDeleteButton,
} from '@src/formik-utils/FieldArrayGrid';
import { useParams } from 'react-router-dom';

export const SPDeleteButtonContainer = ({ deleteMutationToUse, version }) => {
  const { orgId, commitId } = useParams();
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);
  const [deleteCNBCConfiguration] = deleteMutationToUse();

  const deleteFuncParams = {
    payload: {
      commit_version: version,
    },
    cnbcId: rowValues[ROW_ID],
    orgId,
    commitId,
  };
  return (
    <FieldArrayGridDeleteButton
      deleteFunc={deleteCNBCConfiguration}
      deleteFuncParams={deleteFuncParams}
    />
  );
};
