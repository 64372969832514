// @flow
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import React, { useContext } from 'react';
import { SecondaryTopBar } from '@components/breadcrumbs/SecondaryTopBar';
import { getSPUserCommitDetailsBreadcrumbs } from '@streaming-projects/utils';
import { SPUserCommitDetailsContext } from '@streaming-projects/user-commit-details/contexts/SPUserCommitDetailsContext';

const SPUserCommitDetailsBreadcrumbs = () => {
  const spOrgContext = useContext(SPOrgContext);
  const spUserCommitDetailsContext = useContext(SPUserCommitDetailsContext);

  return (
    <SecondaryTopBar
      sectionsToShow={getSPUserCommitDetailsBreadcrumbs(spOrgContext, spUserCommitDetailsContext)}
      showLastUpdatedMessage={false}
    />
  );
};

export default SPUserCommitDetailsBreadcrumbs;
