// @flow
import React, { useContext } from 'react';
import { UserContext } from '@src/contexts/UserContext';
import { getIfUserIsAdminBasedOnGivenDetails } from '@src/common-utils/utils';
import { SP_USER_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/user-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { BooleanSelectField } from '@src/formik-utils/FormFields';

export const SPUserCommitCNBCContainer = () => {
  const user = useContext(UserContext);
  const isUserAdmin = getIfUserIsAdminBasedOnGivenDetails(user);

  const { CREDITS_AND_BURSTS } = BACKEND_FIELDNAMES;
  const { backendFieldName, displayName } =
    SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(CREDITS_AND_BURSTS);

  return (
    <BooleanSelectField
      disabled={!isUserAdmin}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
    />
  );
};
