// @flow
import React, { useCallback } from 'react';
import {
  useGetSPUserCommitPricingStatusViewQuery,
  useSubmitRecalcRequestForUserCommitMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import SPAsyncCalcWrapper from '@components/common/pricing-summary-wrapper/SPAsyncCalcWrapper';

import { SPUserCommitMonthlySummaryContainerWrapper } from './SPUserCommitMonthlySummaryContainerWrapper';

export const SPUserCommitSummaryContainer = () => {
  const pricingStatusFetchingFunction = useCallback(useGetSPUserCommitPricingStatusViewQuery, []);

  return (
    <SPAsyncCalcWrapper
      pricingStatusFetchingFunction={pricingStatusFetchingFunction}
      useRecalcRequestMutation={useSubmitRecalcRequestForUserCommitMutation}
    >
      <SPUserCommitMonthlySummaryContainerWrapper />
    </SPAsyncCalcWrapper>
  );
};
