// @flow
import React, { useCallback, useMemo } from 'react';
import { FieldArray, Formik } from 'formik';
import {
  useGetStreamingProjectSharedWithUsersInfoQuery,
  useShareStreamingProjectMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { validationSchema } from '@components/estimate-sharing/utils';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { EstimateShareEmailIdsTable } from '@components/estimate-sharing/EstimateShareEmailIdsTable';
import { Spacer } from '@presentational/spacing/Spacer';
import { AddEmailContainer } from '@components/estimate-sharing/AddEmailContainer';
import { SharingConfigLevelErrorMessagesContainer } from '@components/estimate-sharing/SharingConfigLevelErrorMessagesContainer';
import { toastError, toastSuccess } from '@presentational/notifications/utils';

export const handleShareSubmit = async (values, shareOrg, orgId) => {
  const body = {
    share_info: values.shared_with_emails,
  };
  const { error } = await shareOrg({
    orgId,
    body,
  });

  if (error) {
    toastError(error, 'Sharing failed. Please double check the email id(s) entered!');
  } else {
    toastSuccess('Org shared successfully! The recipient(s) were notified on Slack!');
  }
};

export const SPORGShareModal = ({ isOpen, setOpen, orgName, orgId }) => {
  const header = `Share Organization ${orgName}`;

  const [shareOrg] = useShareStreamingProjectMutation();

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { orgId: orgId },
          {
            refetchOnMountOrArgChange: true,
            skip: !orgId || !isOpen,
          },
        ],
        [orgId, isOpen]
      )}
      dataFetchingFunction={useCallback(useGetStreamingProjectSharedWithUsersInfoQuery, [])}
    >
      {(data) => {
        // todo::Move this to outside by changing all hooks to Variables - Check at other places too
        if (!isOpen) {
          return null;
        }
        const initialValues = {
          shared_with_emails: data?.shared_users ?? [],
        };

        return (
          <StyledContainer>
            <Formik
              autoComplete="off"
              initialValues={initialValues}
              onSubmit={async (values) => {
                await handleShareSubmit(values, shareOrg, orgId);
                setOpen(false);
              }}
              validateOnMount={true}
              validationSchema={validationSchema}
            >
              {(shareOrgFormik) => (
                <ConfirmModal
                  body={
                    <>
                      <FieldArray
                        name="shared_with_emails"
                        render={(arrayHelpers) => {
                          return (
                            <>
                              <EstimateShareEmailIdsTable arrayHelpers={arrayHelpers} />
                              <Spacer y={40} />
                              <AddEmailContainer arrayHelpers={arrayHelpers} />
                            </>
                          );
                        }}
                      />
                      <Spacer y={20} />
                      <SharingConfigLevelErrorMessagesContainer />
                    </>
                  }
                  centered={false}
                  disabled={!shareOrgFormik.isValid}
                  header={header}
                  isOpen={isOpen}
                  okButtonText="Share"
                  onClickHandlerForCancel={() => {
                    shareOrgFormik.resetForm();
                    setOpen(false);
                  }}
                  onClickHandlerForOK={async () => {
                    shareOrgFormik.submitForm();
                  }}
                />
              )}
            </Formik>
          </StyledContainer>
        );
      }}
    </DataFetcherContainer>
  );
};
