// @flow
import React from 'react';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Formik } from 'formik';
import { PromptIfFormHasUnSavedChanges } from '@presentational/PromptIfFormHasUnSavedChanges';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { SP_CNBC_BACKEND_FIELDNAMES } from '@streaming-projects/credits-and-bursts/enums';
import { SP_CREDITS_AND_BURSTS_JSON_CONFIG } from '@streaming-projects/credits-and-bursts/config';
import { IS_CNBC_SECTION_LIVE } from '@streaming-projects/constants';

import { SP_DEFAULT_COMMIT_DETAILS_CONFIG } from './config';
import SPDefaultCommitDetailsAccordions from './accordions-container/SPDefaultCommitDetailsAccordions';
import { useSPDefaultCommitDetailsContext } from './context/SPDefaultCommitDetailsContext';

const SPDefaultCommitDetailsTopLevelContainerForm = () => {
  const spDefaultCommitDetailsContext = useSPDefaultCommitDetailsContext();

  const inputData = {
    ...spDefaultCommitDetailsContext.commit,
    ...(IS_CNBC_SECTION_LIVE
      ? {
          [SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_JSON]:
            spDefaultCommitDetailsContext?.commit_cnbc_inputs ?? [],
        }
      : {}),
  };

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(
      [
        ...SP_DEFAULT_COMMIT_DETAILS_CONFIG,
        ...(IS_CNBC_SECTION_LIVE ? [SP_CREDITS_AND_BURSTS_JSON_CONFIG] : []),
      ],
      inputData
    );

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {() => (
          <Form autoComplete="off">
            <PromptIfFormHasUnSavedChanges />
            <SPDefaultCommitDetailsAccordions />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};

export default SPDefaultCommitDetailsTopLevelContainerForm;
