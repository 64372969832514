// @flow
import React, { useContext } from 'react';
import { getDropdownOptionsFromArray } from '@src/common-utils/utils';
import { SP_USER_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/user-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SelectField } from '@src/formik-utils/FormFields';
import { useFormikContext } from 'formik';
import {
  MARKETPLACE_NONE,
  PAYMENT_SCHEDULE_PREPAID,
} from '@streaming-projects/user-commit-details/enums';

import { SPUserCommitMetadataContext } from '../contexts/SPUserCommitMetadataContext';

const calculatePaymentScheduleValue = (value, values) => {
  if (value !== MARKETPLACE_NONE) {
    return PAYMENT_SCHEDULE_PREPAID;
  } else {
    return values[BACKEND_FIELDNAMES.PAYMENT_SCHEDULE];
  }
};

export const SPUserCommitMarketplaceContainer = () => {
  const { values, setFieldValue } = useFormikContext();
  const spUserCommitMetadata = useContext(SPUserCommitMetadataContext);
  const marketplaceTypes = spUserCommitMetadata?.commit_config?.marketplace ?? [];
  const marketplaceOptions = getDropdownOptionsFromArray(marketplaceTypes);
  const { backendFieldName, displayName } = SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.MARKETPLACE
  );

  return (
    <SelectField
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      onChange={(e, value) => {
        setFieldValue(
          BACKEND_FIELDNAMES.PAYMENT_SCHEDULE,
          calculatePaymentScheduleValue(value, values)
        );
      }}
      options={marketplaceOptions}
    />
  );
};
