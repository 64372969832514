// @flow
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import { Spacer } from '@presentational/spacing/Spacer';
import { useParams } from 'react-router-dom';
import { useUpdateSPUserCommitDetailsMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { SP_USER_COMMIT_AMOUNT_DETAILS_CONFIG } from '@streaming-projects/user-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { toastError } from '@presentational/notifications/utils';
import { SPUserCommitDetailsContext } from '@streaming-projects/user-commit-details/contexts/SPUserCommitDetailsContext';

import { SPUserCommitAmountContainer } from './SPUserCommitAmountContainer';

export const SPUserCommitAmountSection = () => {
  const { orgId, commitId } = useParams();
  const { version } = useContext(SPUserCommitDetailsContext);
  const [updateUserCommitDetails] = useUpdateSPUserCommitDetailsMutation();
  const fieldsConfig = SP_USER_COMMIT_AMOUNT_DETAILS_CONFIG;

  const handleSave = async (payload) => {
    const { error } = await updateUserCommitDetails({
      orgId: orgId,
      commitId: commitId,
      payload: {
        user_entered_commit_details: {
          [BACKEND_FIELDNAMES.COMMIT_AMOUNT]: Number(payload[BACKEND_FIELDNAMES.COMMIT_AMOUNT]),
        },
        version: version,
      },
    });
    if (error) {
      toastError(error);
    }
  };

  return (
    <PaddedAndRaisedSegment>
      <Grid>
        <Grid.Column width={4}>
          <SPUserCommitAmountContainer />
        </Grid.Column>
      </Grid>
      <Spacer y={40} />
      <GenericSaveResetButtons fieldsConfig={fieldsConfig} onSaveHandler={handleSave} />
    </PaddedAndRaisedSegment>
  );
};
