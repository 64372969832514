// @flow
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetSPUserCommitMonthlyPricingViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { MonthlySpendSummaryContainer } from '@components/common/MonthlySpendSummaryContainer';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import { getMonthsAndYearsTransformedMonthlySpendSummary } from '@streaming-projects/utils';

export const SPUserCommitMonthlySummaryContainerWrapper = () => {
  const params = useParams();

  const dataFetchingArgs = useMemo(
    () => [{ ...params }, { skip: shouldSkipDataFetching(params) }],
    [params]
  );

  const dataFetchingFunction = useCallback(useGetSPUserCommitMonthlyPricingViewQuery, []);

  return (
    <DataFetcherContainer
      dataFetchingArgs={dataFetchingArgs}
      dataFetchingFunction={dataFetchingFunction}
    >
      {(data) => {
        const monthlySpendSummary = getMonthsAndYearsTransformedMonthlySpendSummary(
          data?.commit_cloud_monthly_spends
        );

        return (
          <MonthlySpendSummaryContainer
            columnNamesToPin={[]}
            columnsBasedOnTotalRow={true}
            monthlySpendSummary={monthlySpendSummary}
            valuesAreCurrencies={false}
          />
        );
      }}
    </DataFetcherContainer>
  );
};
