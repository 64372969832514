// @flow
import React, { useContext } from 'react';
import {
  FieldArrayGridConfigItemContext,
  FieldArrayGridSelectField,
} from '@src/formik-utils/FieldArrayGrid';

import { SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP } from '../config';
import { SP_CNBC_BACKEND_FIELDNAMES } from '../enums';

const getYearDropdownOptions = ({ calendarYears, commitCnbcInputs, initiallySelectedYear }) => {
  /*
Calendar Years will be an array of objects and each object will have year_number,calendar_year and months_in_year

In that list, we should first get the list of all Years which are still not taken
And post that, we need to add the initially selected year for that particular row

Example:
Assume 25, 26, 27 are the universe of years
Assume 25 and 26 are already taken
Now, in 25th row, we need to show 25 and 27
In 26th row, we need to show 26 and 27

In order to simplify the code, we will iterate over the array and remove elems which are taken and also keep the initiallySelectedYear
   */

  const calendarYearsToUse = calendarYears.filter((calendarYearObj) => {
    const currentCalendarYearNumber = calendarYearObj.year_number;

    // If it is initiallySelectedYear, then, keep it
    if (currentCalendarYearNumber === initiallySelectedYear) {
      return true;
    }

    // Otherwise, if it is NOT taken, then, keep it
    const isCurrentCalendarYearAlreadyTaken = commitCnbcInputs.find(
      (commitCNBCInputRow) => currentCalendarYearNumber === commitCNBCInputRow.year_number
    );

    return !isCurrentCalendarYearAlreadyTaken;
  });

  return calendarYearsToUse.map((x) => ({
    name: x.year_number,
    display_name: x.calendar_year,
  }));
};

export const SPYearContainer = ({ commitCnbcInputs, calendarYears }) => {
  const { rowValues } = useContext(FieldArrayGridConfigItemContext);
  const initiallySelectedYear = commitCnbcInputs.find(({ id }) => id === rowValues.id)?.year_number;
  const yearDropdownOptions = getYearDropdownOptions({
    calendarYears,
    commitCnbcInputs,
    initiallySelectedYear,
  });
  const cnbcYearConfig = SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP.get(
    SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_YEAR_NUMBER
  );
  return <FieldArrayGridSelectField columnConfig={cnbcYearConfig} options={yearDropdownOptions} />;
};
