// @flow
import React from 'react';
import { TextField } from '@src/formik-utils/FormFields';
import { SP_USER_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/user-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';

export const SPUserCommitOpportunityIDContainer = () => {
  const opportunityIdConfig = SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.SFDC_OPPORTUNITY_ID
  );
  const { displayName, backendFieldName, maxLength } = opportunityIdConfig;

  return (
    <TextField fieldDisplayName={displayName} fieldName={backendFieldName} maxLength={maxLength} />
  );
};
