// @flow
import { convertConfigArrayToFrontEndFormat } from '@src/common-utils/utils';
import { SELECT_INPUT_TYPE, TEXT_NUMBER_INPUT_TYPE } from '@src/formik-utils/consts';
import { convertConfigArrayToMap } from '@src/configuration/utils';
import { object, array, number } from 'yup';
import { CREDITS_AND_BURSTS_MAX_CONFIGURABLE_LIMIT } from '@src/constants';

import { SP_CNBC_BACKEND_FIELDNAMES } from './enums';

const cnbcValidationSchemaFunc = () => {
  return array(
    object({
      [SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_YEAR_NUMBER]: number()
        .label('Year')
        .typeError('Year is required!')
        .required(),

      [SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_CREDITS_AMOUNT]: number()
        .integer()
        .label('Credits Amount')
        .typeError('Credits Amount is required!')
        .min(0)
        .max(
          CREDITS_AND_BURSTS_MAX_CONFIGURABLE_LIMIT,
          'Cannot add more than 1 billion of credits!'
        )
        .required(),
      [SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_BURSTS_AMOUNT]: number()
        .integer()
        .label('Bursts Amount')
        .typeError('Bursts Amount is required!')
        .min(0)
        .max(CREDITS_AND_BURSTS_MAX_CONFIGURABLE_LIMIT, 'Cannot add more than 1 billion of bursts!')
        .required(),
    })
  );
};

export const SP_CREDITS_AND_BURSTS_JSON_CONFIG = {
  backendFieldName: SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_JSON,
  inputTransformationFunc: (rows) => convertConfigArrayToFrontEndFormat(rows),
  validation: cnbcValidationSchemaFunc,
};

export const SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG = [
  {
    backendFieldName: SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_YEAR_NUMBER,
    displayName: 'Year',
    hidden: false,
    columnWidth: 2,
    colDefaultValue: null,
    type: SELECT_INPUT_TYPE,
  },
  {
    backendFieldName: SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_CREDITS_AMOUNT,
    hidden: false,
    displayName: 'Credits',
    columnWidth: 2,
    colDefaultValue: 0,
    icon: 'dollar',
    type: TEXT_NUMBER_INPUT_TYPE,
  },
  {
    backendFieldName: SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_BURSTS_AMOUNT,
    hidden: false,
    displayName: 'Burst Capacity',
    columnWidth: 2,
    colDefaultValue: 0,
    icon: 'dollar',
    type: TEXT_NUMBER_INPUT_TYPE,
  },
];

export const SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP = convertConfigArrayToMap(
  SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG
);
