// @flow
import { useParams } from 'react-router-dom';
import React, { useCallback, useMemo, useState } from 'react';
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { useGetSPOrgLevelViewQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { QueryError } from '@presentational/QueryError';
import { SPORGRequestModal } from '@streaming-projects/orgs/org-top-level/sharing/SPORGRequestModal';
import { IS_ORG_SHARING_LIVE } from '@streaming-projects/constants';

import { SPOrgContext } from './SPOrgContext';

export const SPOrgContextProvider = ({ children }) => {
  const { orgId } = useParams();
  const [isRequestAccessModalOpen, setIsRequestAccessModalOpen] = useState(true);

  return (
    <DataFetcherContainer
      dataFetchingArgs={useMemo(
        () => [
          { orgId },
          {
            skip: !orgId,
          },
        ],

        [orgId]
      )}
      dataFetchingFunction={useCallback(useGetSPOrgLevelViewQuery, [])}
    >
      {(data, error) => {
        if (error) {
          if (error?.status === 403 && IS_ORG_SHARING_LIVE) {
            return (
              <SPORGRequestModal
                isOpen={isRequestAccessModalOpen}
                orgId={orgId}
                setOpen={setIsRequestAccessModalOpen}
              />
            );
          } else {
            return <QueryError error={error} />;
          }
        }

        const dataToPass = {
          ...data,
          isSFDCLinked: data?.org?.is_sfdc_linked ?? false,
        };

        return <SPOrgContext.Provider value={dataToPass}>{children}</SPOrgContext.Provider>;
      }}
    </DataFetcherContainer>
  );
};
