// @flow
import React from 'react';
import { TextField } from '@src/formik-utils/FormFields';
import { SP_USER_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/user-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';

export const SPUserCommitNameContainer = ({ disableOnFormErrors = null }) => {
  const commitNameConfig = SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(BACKEND_FIELDNAMES.NAME);
  const { displayName, backendFieldName } = commitNameConfig;

  return (
    <TextField
      disableOnFormErrors={disableOnFormErrors}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
    />
  );
};
