// @flow
import React from 'react';
import { getAccordianPanelDetailsObject } from '@src/common-utils/utils';
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { SPUserCommitGenericDetailsContainer } from '@streaming-projects/user-commit-details/generic-details/SPUserCommitGenericDetailsContainer';
import { useFormikContext } from 'formik';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SPCreditsAndBurstsContainer } from '@streaming-projects/credits-and-bursts/SPCreditsAndBurstsContainer';
import {
  useCreateSPUserCommitCNBCMutation,
  useDeleteSPUserCommitCNBCMutation,
  useUpdateSPUserCommitCNBCMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';
import useIsAdmin from '@streaming-projects/useIsAdmin';
import { StyledLabel } from '@src/common-utils/styledComponents';
import { Spacer } from '@presentational/spacing/Spacer';
import { IS_USER_COMMITS_DISCOUNTS_PUSH_TO_SFDC_LIVE } from '@streaming-projects/constants';

import { SPUserCommitSFDCDetailsContainer } from '../sfdc-details/SPUserCommitSFDCDetailsContainer';
import { SPUserCommitSummaryContainer } from '../monthly-summary/SPUserCommitSummaryContainer';
import { SPUserCommitAmountSection } from '../commit-amount/SPUserCommitAmountSection';
import { SPUserCommitSPSelectionContainerWrapper } from '../sp-selection/SPUserCommitSPSelectionContainerWrapper';
import { useSPUserCommitDetailsContext } from '../contexts/SPUserCommitDetailsContext';

import { SPPushDiscountsToSFDCBtn } from './SPPushDiscountsToSFDCBtn';

export const SPUserCommitDetailsAccordians = () => {
  const { initialValues } = useFormikContext();
  const isUserAdmin = useIsAdmin();

  const accordionPanels = [
    getAccordianPanelDetailsObject('Commit Details', <SPUserCommitGenericDetailsContainer />),
    getAccordianPanelDetailsObject(
      IS_USER_COMMITS_DISCOUNTS_PUSH_TO_SFDC_LIVE ? (
        <>
          <StyledLabel basic={true} content="SFDC Contract Details" />
          <Spacer x={10} />
          <SPPushDiscountsToSFDCBtn />
        </>
      ) : (
        'SFDC Contract Details'
      ),
      <SPUserCommitSFDCDetailsContainer />,
      'SFDC Contract Details'
    ),

    getAccordianPanelDetailsObject('SP Selection', <SPUserCommitSPSelectionContainerWrapper />),
    getAccordianPanelDetailsObject('Commit Amount', <SPUserCommitAmountSection />),
  ];

  if (initialValues?.[BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_ENABLED]) {
    accordionPanels.push(
      getAccordianPanelDetailsObject(
        'Credits And Bursts',
        <SPCreditsAndBurstsContainer
          createMutationToUse={useCreateSPUserCommitCNBCMutation}
          deleteMutationToUse={useDeleteSPUserCommitCNBCMutation}
          getContextData={useSPUserCommitDetailsContext}
          readOnlyMode={!isUserAdmin}
          updateMutationToUse={useUpdateSPUserCommitCNBCMutation}
        />
      )
    );
  }

  accordionPanels.push(
    getAccordianPanelDetailsObject('User Commit Pricing Summary', <SPUserCommitSummaryContainer />)
  );

  return (
    <AccordionsList
      initialIndexes={[0, 1, 2, 3]}
      panels={accordionPanels}
      showOnlyTitleInCollapsedState={true}
    />
  );
};
