// @flow
import { PaddedAndRaisedSegment } from '@presentational/PaddedAndRaisedSegment';
import React, { useContext } from 'react';
import { Grid } from 'semantic-ui-react';
import { useParams } from 'react-router-dom';
import { StyledGridRow } from '@src/common-utils/styledComponents';
import { Spacer } from '@presentational/spacing/Spacer';
import { GenericSaveResetButtons } from '@components/GenericSaveResetButtons';
import { useUpdateSPUserCommitDetailsMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { SP_USER_COMMIT_SFDC_CONTRACT_DETAILS_CONFIG } from '@streaming-projects/user-commit-details/config';
import { toastError } from '@presentational/notifications/utils';
import { SPUserCommitDetailsContext } from '@streaming-projects/user-commit-details/contexts/SPUserCommitDetailsContext';

import { SPUserCommitOpportunityIDContainer } from './SPUserCommitOpportunityIDContainer';
import { SPUserCommitQuoteNameContainer } from './SPUserCommitQuoteNameContainer';
import { SPUserCommitOpportunityNameContainer } from './SPUserCommitOpportunityNameContainer';

export const SPUserCommitSFDCDetailsContainer = () => {
  const { orgId, commitId } = useParams();
  const { version } = useContext(SPUserCommitDetailsContext);
  const [updateUserCommitDetails] = useUpdateSPUserCommitDetailsMutation();
  const fieldsConfig = SP_USER_COMMIT_SFDC_CONTRACT_DETAILS_CONFIG;

  return (
    <PaddedAndRaisedSegment>
      <Grid>
        <StyledGridRow columns={3}>
          <Grid.Column>
            <SPUserCommitOpportunityNameContainer />
          </Grid.Column>
          <Grid.Column>
            <SPUserCommitOpportunityIDContainer />
          </Grid.Column>
          <Grid.Column>
            <SPUserCommitQuoteNameContainer />
          </Grid.Column>
        </StyledGridRow>
      </Grid>

      <Spacer y={40} />
      <GenericSaveResetButtons
        fieldsConfig={fieldsConfig}
        onSaveHandler={async (payload) => {
          const { error } = await updateUserCommitDetails({
            orgId,
            commitId,
            payload: {
              commit_sfdc_details: payload,
              version: version,
            },
          });
          if (error) {
            toastError(error);
          }
        }}
      />
    </PaddedAndRaisedSegment>
  );
};
