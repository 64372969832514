// @flow
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import {
  shouldSkipDataFetching,
  getSortedFieldValuesFromArrayOfObjects,
} from '@src/common-utils/utils';
import { useGetSPUserCommitDetailsQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';
import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import cloneDeep from 'lodash/cloneDeep';

import { SPUserCommitDetailsContext } from './SPUserCommitDetailsContext';

export const SPUserCommitDetailsContextProvider = ({ children }) => {
  const params = useParams();

  const dataFetchingArgs = useMemo(
    () => [{ ...params }, { skip: shouldSkipDataFetching(params) }],
    [params]
  );
  const dataFetchingFunction = useCallback(useGetSPUserCommitDetailsQuery, []);

  return (
    <DataFetcherContainer
      dataFetchingArgs={dataFetchingArgs}
      dataFetchingFunction={dataFetchingFunction}
    >
      {(data) => {
        const dataCopy = cloneDeep(data);
        const sortedListOfSelectedSPsInThisUserCommit = data.commit_sp_mappings
          ? getSortedFieldValuesFromArrayOfObjects(data.commit_sp_mappings, 'sp_id')
          : [];

        dataCopy.sorted_list_of_initial_selected_sp_ids = sortedListOfSelectedSPsInThisUserCommit;

        return (
          <SPUserCommitDetailsContext.Provider value={dataCopy}>
            {children}
          </SPUserCommitDetailsContext.Provider>
        );
      }}
    </DataFetcherContainer>
  );
};
