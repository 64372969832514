// @flow
import { AccordionsList } from '@presentational/accordions/AccordionsList';
import { getAccordianPanelDetailsObject } from '@src/common-utils/utils';
import React from 'react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { useFormikContext } from 'formik';
import useIsAdmin from '@streaming-projects/useIsAdmin';
import { SPCreditsAndBurstsContainer } from '@streaming-projects/credits-and-bursts/SPCreditsAndBurstsContainer';
import {
  useCreateSPDefaultCommitCNBCMutation,
  useDeleteSPDefaultCommitCNBCMutation,
  useUpdateSPDefaultCommitCNBCMutation,
} from '@streaming-projects/service-definitions/streamingProjectsApi';

import SPDefaultCommitSummaryContainer from './default-commit-pricing-summary/SPDefaultCommitSummaryContainer';
import SPDefaultCommitValueDetails from './default-commit-value/SPDefaultCommitValueDetails';
import SPDefaultCommitGenericDetails from './default-commit-generic-details/SPDefaultCommitGenericDetails';

/**
 * Details page of a commit which is shown when the user clicks on a commit in the Org Commit Summary grid
 */
const SPDefaultCommitDetailsAccordions = () => {
  const { initialValues } = useFormikContext();
  const isUserAdmin = useIsAdmin();

  const accordionPanels = [
    getAccordianPanelDetailsObject('Commit Details', <SPDefaultCommitGenericDetails />),
    getAccordianPanelDetailsObject('Enter Commit Value', <SPDefaultCommitValueDetails />),
  ];

  if (initialValues?.[BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_ENABLED]) {
    accordionPanels.push(
      getAccordianPanelDetailsObject(
        'Credits And Bursts',
        <SPCreditsAndBurstsContainer
          createMutationToUse={useCreateSPDefaultCommitCNBCMutation}
          deleteMutationToUse={useDeleteSPDefaultCommitCNBCMutation}
          readOnlyMode={!isUserAdmin}
          updateMutationToUse={useUpdateSPDefaultCommitCNBCMutation}
        />
      )
    );
  }

  accordionPanels.push(
    getAccordianPanelDetailsObject('Commit Pricing Summary', <SPDefaultCommitSummaryContainer />)
  );

  return (
    <AccordionsList
      initialIndexes={[0, 1]}
      panels={accordionPanels}
      showOnlyTitleInCollapsedState={true}
    />
  );
};

export default SPDefaultCommitDetailsAccordions;
