// @flow
import React from 'react';
import { FieldArrayGridTextField } from '@src/formik-utils/FieldArrayGrid';

import { SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP } from '../config';
import { SP_CNBC_BACKEND_FIELDNAMES } from '../enums';

export const SPCreditsAmountContainer = () => {
  const cnbcCreditsAmountConfig = SP_CREDITS_AND_BURSTS_CONFIG_ARRAY_CONFIG_MAP.get(
    SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_CREDITS_AMOUNT
  );

  return <FieldArrayGridTextField columnConfig={cnbcCreditsAmountConfig} />;
};
