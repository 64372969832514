// @flow
import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { StreamingProjectsHome } from '@streaming-projects/home-page/StreamingProjectsHome';
import {
  getLinkForOrganizationsPage,
  getLinkForSFDCAccountViewPage,
  getLinkForSFDCSPRecordCreateIntermediaryPage,
  getLinkForSFDCViewsIntermediaryPage,
  getLinkForSPDefaultCommitDetailsPage,
  getLinkForSPForecastStatusPage,
  getLinkForSPUserCommitDetailsPage,
  getLinkForStreamingProjectsClusterLinkingWorkloadsPage,
  getLinkForStreamingProjectsClusterWorkloadsPage,
  getLinkForStreamingProjectsFlinkWorkloadsPage,
  getLinkForStreamingProjectsHomePage,
  getLinkForStreamingProjectsSGWorkloadsPage,
  getLinkForStreamingProjectsSPPage,
} from '@streaming-projects/links';
import { FlinkPoolTopLevelContainer } from '@components/flink-pool-top-level/FlinkPoolTopLevelContainer';
import { SPOrgTopLevelContainer } from '@streaming-projects/orgs/org-top-level/SPOrgTopLevelContainer';
import { SPStreamGovernanceWorkloadTopLevelContainer } from '@streaming-projects/workloads/stream-governance-workloads/SPStreamGovernanceWorkloadTopLevelContainer';
import { SPFlinkWorkloadTopLevelContainer } from '@streaming-projects/workloads/flink-workloads/SPFlinkWorkloadTopLevelContainer';
import { SPStreamingProjectTopLevelContainer } from '@streaming-projects/sp-page/SPStreamingProjectTopLevelContainer';
import { SPClusterLinkingWorkloadTopLevelContainer } from '@streaming-projects/workloads/cluster-linking-workloads/SPClusterLinkingWorkloadTopLevelContainer';
import { SPClusterWorkloadTopLevelContainer } from '@streaming-projects/workloads/cluster-workloads/SPClusterWorkloadTopLevelContainer';
import SPDefaultCommitDetailsTopLevelContainer from '@streaming-projects/default-commit-details/SPDefaultCommitDetailsTopLevelContainer';
import { SFDCSPRecordCreateIntermediaryPage } from '@src/SFDCSPRecordCreateIntermediaryPage';
import { SPUserCommitDetailsTopLevelContainer } from '@streaming-projects/user-commit-details/SPUserCommitDetailsTopLevelContainer';
import { SFDCSPViewsIntermediaryPage } from '@src/SFDCSPViewsIntermediaryPage';
import { SFDCAccountsPage } from '@streaming-projects/home-page/sfdc-accounts-page/SFDCAccountsPage';
import { SPForecastStatusPageTopLevelContainer } from '@streaming-projects/forecast-status-page/SPForecastStatusPageTopLevelContainer';

import {
  getLinkForCluster,
  getLinkForEstimate,
  getLinkForFlinkPool,
  getLinkForHomePage,
  getLinkForStreamGovernance,
} from './components/links/utils';
import { StreamGovernanceTopLevelContainer } from './components/stream-governance/StreamGovernanceTopLevelContainer';
import EstimateTopLevelContainer from './components/estimate-top-level/EstimateTopLevelContainer';
import ClusterTopLevelContainer from './components/cluster/cluster-top-level/ClusterTopLevelContainer';
import { TopBar } from './components/main-header/TopBar';
import { getThemeDetails, GlobalStyle } from './theming/themes';
import { CookieContext } from './contexts/CookieContext';
import {
  DEFAULT_BACKGROUND_COLOR_FOR_DARK_THEME,
  DEFAULT_BACKGROUND_COLOR_FOR_LIGHT_THEME,
  THEME_LIGHT,
} from './constants';
import { Spacer } from './components/presentational/spacing/Spacer';
import { oktaAuthConfig } from './authentication/OKTAConfig';
import AuthLogout from './authentication/AuthLogout';
import { NotFoundPage } from './components/NotFoundPage';
import { LoadingIndicator } from './components/presentational/LoadingIndicator';

const oktaAuth = new OktaAuth(oktaAuthConfig);

const Home = React.lazy(() => import(/* webpackChunkName: "Home" */ './components/home-page/Home'));

const AppRoutes = () => {
  const { cookies } = useContext(CookieContext);
  const theme = cookies?.theme ?? THEME_LIGHT;

  return (
    <ThemeProvider theme={getThemeDetails(theme)}>
      <GlobalStyle
        backgroundColor={
          theme === THEME_LIGHT
            ? DEFAULT_BACKGROUND_COLOR_FOR_LIGHT_THEME
            : DEFAULT_BACKGROUND_COLOR_FOR_DARK_THEME
        }
      />
      <TopBar />

      <React.Suspense fallback={<LoadingIndicator />}>
        <Switch>
          <SecureRoute exact={true} path={getLinkForHomePage()}>
            <Home />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForEstimate(':estimateId')}>
            <EstimateTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForCluster(':estimateId', ':clusterId')}>
            <ClusterTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForCluster(':estimateId')}>
            <ClusterTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForStreamGovernance(':estimateId')}>
            <StreamGovernanceTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForFlinkPool(':estimateId', ':flinkPoolId')}>
            <FlinkPoolTopLevelContainer />
          </SecureRoute>

          <Route component={LoginCallback} path="/login/oauth2/code/okta" />
          <Route
            path="/login"
            render={() => {
              oktaAuth.signInWithRedirect();
            }}
          />
          <Route path="/logout" render={() => <AuthLogout />} />

          {/* Streaming Projects Links */}
          <SecureRoute exact={true} path={getLinkForStreamingProjectsHomePage()}>
            <StreamingProjectsHome />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForSFDCSPRecordCreateIntermediaryPage()}>
            <SFDCSPRecordCreateIntermediaryPage />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForSFDCViewsIntermediaryPage()}>
            <SFDCSPViewsIntermediaryPage />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForSFDCAccountViewPage(':sfdcAccountId')}>
            <SFDCAccountsPage />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForOrganizationsPage(':orgId')}>
            <SPOrgTopLevelContainer />
          </SecureRoute>

          <SecureRoute
            exact={true}
            path={getLinkForStreamingProjectsFlinkWorkloadsPage(
              ':orgId',
              ':spId',
              ':flinkWorkloadId'
            )}
          >
            <SPFlinkWorkloadTopLevelContainer />
          </SecureRoute>

          <SecureRoute
            exact={true}
            path={getLinkForStreamingProjectsClusterWorkloadsPage(
              ':orgId',
              ':spId',
              ':clusterWorkloadId'
            )}
          >
            <SPClusterWorkloadTopLevelContainer />
          </SecureRoute>

          <SecureRoute
            exact={true}
            path={getLinkForStreamingProjectsClusterLinkingWorkloadsPage(
              ':orgId',
              ':spId',
              ':clusterLinkingWorkloadId'
            )}
          >
            <SPClusterLinkingWorkloadTopLevelContainer />
          </SecureRoute>

          <SecureRoute
            exact={true}
            path={getLinkForStreamingProjectsSGWorkloadsPage(
              ':orgId',
              ':spId',
              ':streamGovernanceWorkloadId'
            )}
          >
            <SPStreamGovernanceWorkloadTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForStreamingProjectsSPPage(':orgId', ':spId')}>
            <SPStreamingProjectTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForSPDefaultCommitDetailsPage(':orgId')}>
            <SPDefaultCommitDetailsTopLevelContainer />
          </SecureRoute>

          <SecureRoute exact={true} path={getLinkForSPUserCommitDetailsPage(':orgId', ':commitId')}>
            <SPUserCommitDetailsTopLevelContainer />
          </SecureRoute>
          <SecureRoute exact={true} path={getLinkForSPForecastStatusPage(':orgId')}>
            <SPForecastStatusPageTopLevelContainer />
          </SecureRoute>

          <Route component={NotFoundPage} />
        </Switch>
      </React.Suspense>
      <Spacer y={100} />
      {/* Spacer is required to give some additional white space at the bottom for aesthetic reasons*/}
    </ThemeProvider>
  );
};

export default AppRoutes;
