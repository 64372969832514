// @flow
import React from 'react';
import { useFormikContext } from 'formik';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';

export const SPUserCommitDateChangeModal = ({ isOpen, setOpen }) => {
  const { initialValues, setFieldValue } = useFormikContext();

  const header = 'Change in one of the dates of Commit';
  const body = (
    <>
      <p>
        You have changed one of the date inputs of this commits. This might mean that duration of
        the Commit would be changed.
      </p>

      <p>
        If you dont want this behaviour, then, please click <strong>No, revert my changes!</strong>
        to go back to the previous state!
      </p>
    </>
  );

  return (
    <ConfirmModal
      body={body}
      cancelButtonText="No, revert my changes!"
      header={header}
      isOpen={isOpen}
      okButtonText="Yes, I understand and want to proceed!"
      onClickHandlerForCancel={async () => {
        await setFieldValue(
          BACKEND_FIELDNAMES.COMMIT_END_DATE,
          initialValues[BACKEND_FIELDNAMES.COMMIT_END_DATE]
        );
        await setFieldValue(
          BACKEND_FIELDNAMES.COMMIT_START_DATE,
          initialValues[BACKEND_FIELDNAMES.COMMIT_START_DATE]
        );
        setOpen(false);
      }}
      onClickHandlerForOK={() => {
        setOpen(false);
      }}
    />
  );
};
