// @flow
import React from 'react';

import {
  constructDataForMonthlySummaryUI,
  getColumnDefsForMonthlySummaryBasedOnGivenData,
} from '../views/utils';
import { PaddedAndRaisedSegment } from '../presentational/PaddedAndRaisedSegment';
import { DataGrid } from '../views/grid-utils/DataGrid';
import { ESTIMATE_MONTHLY_SPEND_SUMMARY } from '../../constants';

export const MonthlySpendSummaryContainer = ({
  monthlySpendSummary,
  disabled = false,
  columnNamesToPin = ['Total', 'Cost (with burst capacity)', 'Cost'],
  addCostMargin = false,
  columnsBasedOnTotalRow = false,
  valuesAreCurrencies = true,
}) => {
  const columnDefs = getColumnDefsForMonthlySummaryBasedOnGivenData(
    monthlySpendSummary,
    valuesAreCurrencies,
    addCostMargin,
    columnsBasedOnTotalRow
  );
  const tableData = constructDataForMonthlySummaryUI(
    monthlySpendSummary,
    'spend_discounted',
    addCostMargin
  );

  return (
    <PaddedAndRaisedSegment disabled={disabled}>
      <DataGrid
        columnDefs={columnDefs}
        label={ESTIMATE_MONTHLY_SPEND_SUMMARY}
        pinnedRowsSelectorFunc={(row) => columnNamesToPin.includes(row.Field)}
        sizeColumnsToFitInitially={true}
        tableData={tableData}
      />
    </PaddedAndRaisedSegment>
  );
};
