// @flow
import { BooleanSelectField } from '@src/formik-utils/FormFields';
import useIsAdmin from '@streaming-projects/useIsAdmin';
import React from 'react';
import { SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/default-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';

export const SPDefaultCommitCreditsAndBurstsEnabledContainer = () => {
  const isUserAdmin = useIsAdmin();
  const cnbEnabledConfig = SP_DEFAULT_COMMIT_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_ENABLED
  );
  const { displayName, backendFieldName } = cnbEnabledConfig;

  return (
    <BooleanSelectField
      disabled={!isUserAdmin}
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
    />
  );
};
