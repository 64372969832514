// @flow
import React from 'react';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { TextField } from '@src/formik-utils/FormFields';
import { SP_USER_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/user-commit-details/config';

export const SPUserCommitAmountContainer = () => {
  const { backendFieldName, displayName, inputType, tooltip } =
    SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(BACKEND_FIELDNAMES.COMMIT_AMOUNT);

  return (
    <TextField
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      fluid={true}
      tooltip={tooltip}
      type={inputType}
    />
  );
};
