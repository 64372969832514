// @flow
import React from 'react';
import { SP_USER_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/user-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { SelectField } from '@src/formik-utils/FormFields';
import { useFormikContext } from 'formik';
import {
  PAYMENT_SCHEDULE_PREPAID,
  PAYMENT_SCHEDULE_ARREARS,
  PAYMENT_SCHEDULE_ARREARS_DISPLAY_NAME,
  MARKETPLACE_NONE,
} from '@streaming-projects/user-commit-details/enums';

const getPaymentScheduleOptions = (values) => {
  // CTODO::SP Use API to get payment schedules
  const paymentScheduleOptions = [
    {
      name: PAYMENT_SCHEDULE_PREPAID,
      display_name: PAYMENT_SCHEDULE_PREPAID,
    },
  ];

  if (values.marketplace.toLowerCase() === MARKETPLACE_NONE.toLowerCase()) {
    paymentScheduleOptions.push({
      name: PAYMENT_SCHEDULE_ARREARS,
      display_name: PAYMENT_SCHEDULE_ARREARS_DISPLAY_NAME,
    });
  }

  return paymentScheduleOptions;
};

export const SPUserCommitPaymentScheduleContainer = () => {
  const { values } = useFormikContext();
  const paymentScheduleOptions = getPaymentScheduleOptions(values);
  const { backendFieldName, displayName } = SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(
    BACKEND_FIELDNAMES.PAYMENT_SCHEDULE
  );

  return (
    <SelectField
      fieldDisplayName={displayName}
      fieldName={backendFieldName}
      options={paymentScheduleOptions}
    />
  );
};
