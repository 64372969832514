// @flow
export const LINK = 'link';
export const BASELINE_SP_IDENTIFIER_NAME = 'Baseline';

export const BACKEND_FIELDNAMES = {
  NAME: 'name',
  ID: 'id',
  LINK: 'link',
  SFDC_ORG_NAME: 'sfdc_org_name',
  SFDC_ORG_ID: 'sfdc_org_id',
  RATE_CARD: 'rate_card',
  ACCOUNT_NAME: 'account_name',
  ACCOUNT_ID: 'account_id',
  SP_NAME: 'name',
  SP_PHASE: 'sp_phase',
  SP_RISK_STATUS: 'sp_risk_status',
  SP_SFDC_ID: 'sfdc_sp_record_id',
  SP_START_DATE: 'start_date',
  SP_END_DATE: 'end_date',
  SP_PROVISION_DATE: 'provision_date',
  SP_GO_LIVE_DATE: 'go_live_date',
  SP_RAMPED_DATE: 'ramp_date',
  WORKLOAD_ID: 'id',
  WORKLOAD_NAME: 'name',
  RESOURCE_ID: 'resource_id',
  RESOURCE_NAME: 'resource_name',
  IS_ENABLED: 'is_enabled',
  IS_SFDC_LINKED: 'is_sfdc_linked',
  DEAL_TYPE: 'deal_type',
  MARKETPLACE: 'marketplace',
  PAYMENT_SCHEDULE: 'payment_schedule',
  CREDITS_AND_BURSTS: 'cnbc_enabled',
  COMMIT_RATE_CARD: 'rateCard',
  COMMIT_START_DATE: 'start_date',
  COMMIT_END_DATE: 'end_date',
  SFDC_OPPORTUNITY_ID: 'opportunity_id',
  SFDC_QUOTE_NAME: 'quote_name',
  SFDC_OPPORTUNITY_NAME: 'opportunity_name',
  IS_SP_SELECTED: 'is_enabled',
  COMMIT_SP_MAPPING_IDS: 'commit_sp_mappings',

  // CTODO::SP check all the below after backend changes
  COMMIT_ID: 'commit_id',
  SUPPORT_TIER: 'support_tier',
  CREDITS_AND_BURSTS_ENABLED: 'cnbc_enabled',
  COMMIT_AMOUNT: 'user_entered_commit_amount',
  DEFAULT_COMMIT_DETAILS: 'default_commit_details',
  COMMITS: 'commit_details',
};

export const FRONT_END_DISPLAY_NAMES = {
  SP_NAME: 'SP Name',
  SP_ID: 'Streaming Project ID',
  SP_START_DATE: 'Start Date',
  SP_END_DATE: 'End Date',
  SP_PROVISION_DATE: 'Provision Date',
  SP_GO_LIVE_DATE: 'Go Live Date',
  SP_RAMPED_DATE: 'Ramp Date',
  SP_PHASE: 'SP Phase',
  SP_RISK_STATUS: 'SP Risk Status',
  WORKLOAD_ID: 'Workload ID',
  WORKLOAD_NAME: 'Workload Name',
  RESOURCE_ID: 'Resource ID',
  RESOURCE_NAME: 'Resource Name',
  IS_ENABLED: 'Enabled',
  COMMIT_NAME: 'Commit Name',
  DEAL_START_DATE: 'Deal Start Date',
  DEAL_END_DATE: 'Deal End Date',
  SUPPORT_TIER: 'Support Tier',
  CREDITS_AND_BURSTS_ENABLED: 'Credits and Bursts',
  COMMIT_AMOUNT: 'Total Commit',
  DEFAULT_COMMIT_NAME: 'Default Commit',

  COMMIT_START_DATE: 'Start Date',
  COMMIT_END_DATE: 'End Date',
  DEAL_TYPE: 'Deal Type',
  MARKETPLACE: 'Marketplace',
  PAYMENT_SCHEDULE: 'Payment Schedule',
  CREDITS_AND_BURSTS: 'Credits and Bursts',
  RATE_CARD: 'Rate Card',
  SFDC_OPPORTUNITY_ID: 'Opportunity Id',
  SFDC_QUOTE_NAME: 'SFDC Quote Name',
  SFDC_OPPORTUNITY_NAME: 'Opportunity Name',
  IS_SP_SELECTED: 'Selected',
};
