// @flow
import React, { useContext, useState } from 'react';
import { SPUserCommitDetailsContext } from '@streaming-projects/user-commit-details/contexts/SPUserCommitDetailsContext';
import { SP_USER_COMMIT_DETAILS_CONFIG } from '@streaming-projects/user-commit-details/config';
import { getFormikFormInputsFromColConfigAndInputSource } from '@src/common-utils/utils';
import { StyledContainer } from '@src/common-utils/styledComponents';
import { Formik } from 'formik';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { SPUserCommitDetailsAccordians } from '@streaming-projects/user-commit-details/accordians-container/SPUserCommitDetailsAccordians';
import { SPOrgContext } from '@streaming-projects/orgs/contexts/SPOrgContext';
import { SP_CNBC_BACKEND_FIELDNAMES } from '@streaming-projects/credits-and-bursts/enums';
import { SP_CREDITS_AND_BURSTS_JSON_CONFIG } from '@streaming-projects/credits-and-bursts/config';
import { DeleteButton } from '@presentational/buttons/DeleteButton';
import { Spacer } from '@presentational/spacing/Spacer';

import { SPUserCommitDeleteWarningModal } from './SPUserCommitDeleteWarningModal';

export const SPUserCommitDetailsTopLevelContainerForm = () => {
  const spUserCommitDetails = useContext(SPUserCommitDetailsContext);
  const spOrgData = useContext(SPOrgContext);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  // CTODO::SP Change in case Rate Card is sent in Commit Page GET response
  const userCommitData = {
    id: spUserCommitDetails.commit_details.id,
    name: spUserCommitDetails.commit_details.name,
    support_tier: spUserCommitDetails.commit_details.support_tier,
    deal_type: spUserCommitDetails.commit_details.deal_type,
    marketplace: spUserCommitDetails.commit_details.marketplace,
    payment_schedule: spUserCommitDetails.commit_details.payment_schedule,
    custom_discount_enabled: spUserCommitDetails.commit_details.custom_discount_enabled,
    cnbc_enabled: spUserCommitDetails.commit_details.cnbc_enabled,
    customer_view_enabled: spUserCommitDetails.commit_details.customer_view_enabled,
    start_date: spUserCommitDetails.commit_details.start_date,
    end_date: spUserCommitDetails.commit_details.end_date,
    user_entered_commit_amount:
      spUserCommitDetails.user_entered_commit_details.user_entered_commit_amount,
    quote_name: spUserCommitDetails.commit_sfdc_details.quote_name,
    opportunity_id: spUserCommitDetails.commit_sfdc_details.opportunity_id,
    opportunity_name: spUserCommitDetails.commit_sfdc_details.opportunity_name,
    rate_card: spOrgData.org.rate_card,
    [SP_CNBC_BACKEND_FIELDNAMES.CREDITS_AND_BURSTS_JSON]:
      spUserCommitDetails?.commit_cnbc_inputs ?? [],
  };

  const { initialValues, initialTouched, validationSchema } =
    getFormikFormInputsFromColConfigAndInputSource(
      [...SP_USER_COMMIT_DETAILS_CONFIG, SP_CREDITS_AND_BURSTS_JSON_CONFIG],
      {
        ...userCommitData,
      }
    );

  return (
    <StyledContainer>
      <Formik
        enableReinitialize={true}
        initialTouched={initialTouched}
        initialValues={initialValues}
        onSubmit={() => {}}
        validateOnMount={true}
        validationSchema={validationSchema}
      >
        {() => (
          <Form autoComplete="off">
            <DeleteButton
              onClick={() => {
                setDeleteModalOpen(true);
              }}
              size="large"
            />
            <SPUserCommitDeleteWarningModal
              commitName={userCommitData.name}
              isOpen={isDeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
            />
            <Spacer y={10} />
            <SPUserCommitDetailsAccordians />
          </Form>
        )}
      </Formik>
    </StyledContainer>
  );
};
