// @flow
import React from 'react';
import {
  convertUTCToLocalDate,
  convertLocalToUTCDate,
  sfdcQuoteIdValidation,
  sfdcOpportunityIdValidation,
  commitAmountValidation,
} from '@src/common-utils/utils';
import { convertConfigArrayToMap } from '@src/configuration/utils';
import {
  BOOLEAN_SELECT_INPUT_TYPE,
  DATE_INPUT_TYPE,
  SELECT_INPUT_TYPE,
  TEXT_INPUT_TYPE,
  TEXT_NUMBER_INPUT_TYPE,
} from '@src/formik-utils/consts';
import { BACKEND_FIELDNAMES, FRONT_END_DISPLAY_NAMES } from '@streaming-projects/enums';
import { string, date } from 'yup';
import { Tooltip } from '@presentational/Tooltip';

export const SP_USER_COMMIT_GENERIC_DETAILS_CONFIG = [
  {
    displayName: FRONT_END_DISPLAY_NAMES.COMMIT_NAME,
    backendFieldName: BACKEND_FIELDNAMES.NAME,
    inputType: TEXT_INPUT_TYPE,
    validation: string()
      .label(FRONT_END_DISPLAY_NAMES.COMMIT_NAME)
      .required()
      .notOneOf(
        [FRONT_END_DISPLAY_NAMES.DEFAULT_COMMIT_NAME],
        `Commit name cannot be "${FRONT_END_DISPLAY_NAMES.DEFAULT_COMMIT_NAME}"`
      ),
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.COMMIT_START_DATE,
    backendFieldName: BACKEND_FIELDNAMES.COMMIT_START_DATE,
    validation: date().label(FRONT_END_DISPLAY_NAMES.COMMIT_START_DATE).required(),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.COMMIT_END_DATE,
    backendFieldName: BACKEND_FIELDNAMES.COMMIT_END_DATE,
    validation: date().label(FRONT_END_DISPLAY_NAMES.COMMIT_END_DATE).required(),
    inputType: DATE_INPUT_TYPE,
    inputTransformationFunc: convertUTCToLocalDate,
    transformationFunctionToSendToBackEnd: convertLocalToUTCDate,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.DEAL_TYPE,
    backendFieldName: BACKEND_FIELDNAMES.DEAL_TYPE,
    inputType: SELECT_INPUT_TYPE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.DEAL_TYPE).required(),
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.MARKETPLACE,
    backendFieldName: BACKEND_FIELDNAMES.MARKETPLACE,
    inputType: SELECT_INPUT_TYPE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.MARKETPLACE).required(),
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.PAYMENT_SCHEDULE,
    backendFieldName: BACKEND_FIELDNAMES.PAYMENT_SCHEDULE,
    inputType: SELECT_INPUT_TYPE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.PAYMENT_SCHEDULE).required(),
  },
  // CTODO::SP Confirm if Rate Card is Required Field
  {
    displayName: FRONT_END_DISPLAY_NAMES.RATE_CARD,
    backendFieldName: BACKEND_FIELDNAMES.RATE_CARD,
    validation: string().label(FRONT_END_DISPLAY_NAMES.RATE_CARD).required(),
    inputType: SELECT_INPUT_TYPE,
    disabled: true,
    tooltip: <Tooltip text="Rate Card is editable from Org Page" />,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.CREDITS_AND_BURSTS,
    backendFieldName: BACKEND_FIELDNAMES.CREDITS_AND_BURSTS,
    inputType: BOOLEAN_SELECT_INPUT_TYPE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.CREDITS_AND_BURSTS).required(),
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SUPPORT_TIER,
    backendFieldName: BACKEND_FIELDNAMES.SUPPORT_TIER,
    inputType: SELECT_INPUT_TYPE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SUPPORT_TIER).required(),
  },
];

export const SP_USER_COMMIT_SFDC_CONTRACT_DETAILS_CONFIG = [
  {
    displayName: FRONT_END_DISPLAY_NAMES.SFDC_OPPORTUNITY_ID,
    backendFieldName: BACKEND_FIELDNAMES.SFDC_OPPORTUNITY_ID,
    inputType: TEXT_INPUT_TYPE,
    validation: sfdcOpportunityIdValidation.label(FRONT_END_DISPLAY_NAMES.SFDC_OPPORTUNITY_ID),
    maxLength: 18,
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SFDC_QUOTE_NAME,
    backendFieldName: BACKEND_FIELDNAMES.SFDC_QUOTE_NAME,
    inputType: TEXT_INPUT_TYPE,
    validation: sfdcQuoteIdValidation.label(FRONT_END_DISPLAY_NAMES.SFDC_QUOTE_NAME),
  },
  {
    displayName: FRONT_END_DISPLAY_NAMES.SFDC_OPPORTUNITY_NAME,
    backendFieldName: BACKEND_FIELDNAMES.SFDC_OPPORTUNITY_NAME,
    inputType: TEXT_INPUT_TYPE,
    validation: string().label(FRONT_END_DISPLAY_NAMES.SFDC_OPPORTUNITY_NAME),
  },
];

export const SP_USER_COMMIT_AMOUNT_DETAILS_CONFIG = [
  {
    displayName: FRONT_END_DISPLAY_NAMES.COMMIT_AMOUNT,
    backendFieldName: BACKEND_FIELDNAMES.COMMIT_AMOUNT,
    inputType: TEXT_NUMBER_INPUT_TYPE,
    tooltip: <Tooltip text="Commit amount signed by the customer." />,
    validation: commitAmountValidation,
  },
];

export const SP_USER_COMMIT_DETAILS_CONFIG = [
  ...SP_USER_COMMIT_GENERIC_DETAILS_CONFIG,
  ...SP_USER_COMMIT_SFDC_CONTRACT_DETAILS_CONFIG,
  ...SP_USER_COMMIT_AMOUNT_DETAILS_CONFIG,
];

export const SP_USER_COMMIT_DETAILS_CONFIG_MAP = convertConfigArrayToMap(
  SP_USER_COMMIT_DETAILS_CONFIG
);
