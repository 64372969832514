// @flow
import {
  getErrorStatusAndDetailFromErrorObject,
  toastError,
  toastSuccess,
} from '@presentational/notifications/utils';
import { useUploadDiscountsToSFDCMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { useParams } from 'react-router-dom';
import React from 'react';
import { ButtonWithPopup } from '@presentational/ButtonWithPopup';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { useFormikContext } from 'formik';

import { useSPUserCommitDetailsContext } from '../contexts/SPUserCommitDetailsContext';

export const SPPushDiscountsToSFDCBtn = () => {
  const { orgId, commitId } = useParams();
  const isSFDCLinked =
    useSPUserCommitDetailsContext()?.commit_sfdc_details?.is_sfdc_linked ?? false;

  const { dirty, initialValues, isValid } = useFormikContext();

  const [uploadDataToSFDCFunc] = useUploadDiscountsToSFDCMutation();

  const isSFDCOpportunityIdSetFromApi =
    (initialValues[BACKEND_FIELDNAMES.SFDC_OPPORTUNITY_ID] ?? '').length > 0;

  const isSFDCQuoteNameSetFromApi =
    (initialValues[BACKEND_FIELDNAMES.SFDC_QUOTE_NAME] ?? '').length > 0;

  const doesSFDCQuoteAndOpportunityIDExist =
    isSFDCOpportunityIdSetFromApi && isSFDCQuoteNameSetFromApi;

  const isDisabled = dirty || !isValid || !doesSFDCQuoteAndOpportunityIDExist || !isSFDCLinked;

  const getTooltipText = () => {
    if (dirty) {
      return 'Please save the changes before pushing to SFDC';
    }
    if (!doesSFDCQuoteAndOpportunityIDExist) {
      return 'Please enter the SFDC Quote name and Opportunity ID';
    }
    if (!isSFDCLinked) {
      return 'Please ensure valid Opportunity ID and SFDC Quote Name is entered';
    }
    return '';
  };

  return (
    <ButtonWithPopup
      circular={true}
      compact={true}
      disabled={isDisabled}
      onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        const { error } = await uploadDataToSFDCFunc({
          orgId,
          commitId,
        });
        if (error) {
          const { errorDetail } = getErrorStatusAndDetailFromErrorObject(error);
          toastError(error, errorDetail);
        } else {
          toastSuccess('Successfully uploaded the data to SFDC!!');
        }
      }}
      popupContent={getTooltipText()}
      size="mini"
      text="Push To SFDC"
    />
  );
};
