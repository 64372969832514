// @flow
import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useAddUserCommitDetailsMutation } from '@streaming-projects/service-definitions/streamingProjectsApi';
import { ConfirmModal } from '@presentational/modals/ConfirmModal';
import { StyledContainer, StyledGridRow } from '@src/common-utils/styledComponents';
import { Form } from '@src/formik-utils/formikSUIWrapper';
import { Grid } from 'semantic-ui-react';
import { Formik } from 'formik';
import { SP_USER_COMMIT_DETAILS_CONFIG_MAP } from '@streaming-projects/user-commit-details/config';
import { BACKEND_FIELDNAMES } from '@streaming-projects/enums';
import { getValidationSchemaFromColsConfig } from '@src/configuration/utils';
import { object } from 'yup';
import { toastError } from '@presentational/notifications/utils';
import { getLinkForSPUserCommitDetailsPage } from '@streaming-projects/links';
import { Spacer } from '@presentational/spacing/Spacer';
import { extractRelevantFieldValuesFromForm } from '@src/common-utils/utils';

import { SPUserCommitStartDateContainer } from '../generic-details/SPUserCommitStartDateContainer';
import { SPUserCommitEndDateContainer } from '../generic-details/SPUserCommitEndDateContainer';
import { SPUserCommitNameContainer } from '../generic-details/SPUserCommitNameContainer';

export const SPAddCommitModal = ({ isOpen, setOpen }) => {
  const [addSPUserCommit] = useAddUserCommitDetailsMutation();
  const { orgId } = useParams();
  const { push } = useHistory();

  const initialValues = {};

  const fieldsConfig = [
    SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(BACKEND_FIELDNAMES.NAME),
    SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(BACKEND_FIELDNAMES.COMMIT_START_DATE),
    SP_USER_COMMIT_DETAILS_CONFIG_MAP.get(BACKEND_FIELDNAMES.COMMIT_END_DATE),
  ];

  const validationSchema = object({
    ...getValidationSchemaFromColsConfig(fieldsConfig),
  });

  return (
    <StyledContainer>
      <Formik
        autocomplete="off"
        initialValues={initialValues}
        onSubmit={() => {}}
        validationSchema={validationSchema}
      >
        {(addSPUserCommitFormik) => (
          <ConfirmModal
            body={
              <StyledContainer>
                <Form autocomplete="off">
                  <Grid>
                    <StyledGridRow columns={2}>
                      <Grid.Column>
                        <SPUserCommitNameContainer disableOnFormErrors={false} />
                      </Grid.Column>
                    </StyledGridRow>
                    <StyledGridRow columns={2} paddingTop="1rem">
                      <Grid.Column>
                        <SPUserCommitStartDateContainer
                          disableOnFormErrors={false}
                          showWarningOnChange={false}
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <SPUserCommitEndDateContainer
                          disableOnFormErrors={false}
                          showWarningOnChange={false}
                        />
                      </Grid.Column>
                    </StyledGridRow>
                    <Spacer y={20} />
                  </Grid>
                </Form>
              </StyledContainer>
            }
            cancelButtonNegative={true}
            disabled={!addSPUserCommitFormik.dirty || !addSPUserCommitFormik.isValid}
            header="Add New Commit"
            isOpen={isOpen}
            okButtonNegative={false}
            okButtonText="Continue"
            onClickHandlerForCancel={() => {
              addSPUserCommitFormik.resetForm();
              return setOpen(false);
            }}
            onClickHandlerForOK={async () => {
              const { error, data } = await addSPUserCommit({
                orgId,
                payload: {
                  ...extractRelevantFieldValuesFromForm(fieldsConfig, addSPUserCommitFormik.values),
                },
              });

              if (error) {
                toastError(error);
              } else {
                push(getLinkForSPUserCommitDetailsPage(orgId, data.commit_details?.id));
              }
              addSPUserCommitFormik.resetForm();
              setOpen(false);
            }}
          />
        )}
      </Formik>
    </StyledContainer>
  );
};
