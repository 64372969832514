// @flow
import React from 'react';
import { Grid } from 'semantic-ui-react';
import { FieldArrayStyledGridRow } from '@src/formik-utils/FieldArrayGrid';

import { SPYearContainer } from './auxilary-components/SPCNBCYearContainer';
import { SPCreditsAmountContainer } from './auxilary-components/SPCreditsAmountContainer';
import { SPBurstsAmountContainer } from './auxilary-components/SPBurstsAmountContainer';
import { SPDeleteButtonContainer } from './auxilary-components/SPCNBCDeleteButtonContainer';
import { SPUpdateButtonContainer } from './auxilary-components/SPCNBCUpdateButtonContainer';

export const SPCreditAndBurstsConfigurationItem = ({
  createMutationToUse,
  updateMutationToUse,
  deleteMutationToUse,
  commitCnbcInputs,
  calendarYears,
  version,
}) => {
  return (
    <FieldArrayStyledGridRow>
      <Grid.Column width={2}>
        <SPYearContainer calendarYears={calendarYears} commitCnbcInputs={commitCnbcInputs} />
      </Grid.Column>
      <Grid.Column width={2}>
        <SPCreditsAmountContainer />
      </Grid.Column>
      <Grid.Column width={2}>
        <SPBurstsAmountContainer />
      </Grid.Column>
      <Grid.Column width={2}>
        <SPUpdateButtonContainer
          createMutationToUse={createMutationToUse}
          updateMutationToUse={updateMutationToUse}
          version={version}
        />
        <SPDeleteButtonContainer deleteMutationToUse={deleteMutationToUse} version={version} />
      </Grid.Column>
    </FieldArrayStyledGridRow>
  );
};
