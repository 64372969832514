// @flow
import { DataFetcherContainer } from '@presentational/DataFetcherContainer';
import { shouldSkipDataFetching } from '@src/common-utils/utils';
import React, { useCallback, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useGetStreamingProjectsMetadataQuery } from '@streaming-projects/service-definitions/streamingProjectsApi';

import { SPUserCommitDetailsContext } from './SPUserCommitDetailsContext';
import { SPUserCommitMetadataContext } from './SPUserCommitMetadataContext';

export const SPUserCommitMetadataContextProvider = ({ children }) => {
  const params = useParams();
  const commitData = useContext(SPUserCommitDetailsContext);

  const query = `COMMIT_CONFIG,START_DATE=${commitData?.commit_details?.start_date},END_DATE=${commitData?.commit_details?.end_date}`;

  const dataFetchingArgs = useMemo(
    () => [{ query, ...params }, { skip: shouldSkipDataFetching(params) }],
    [params, query]
  );
  const dataFetchingFunction = useCallback(useGetStreamingProjectsMetadataQuery, []);

  return (
    <DataFetcherContainer
      dataFetchingArgs={dataFetchingArgs}
      dataFetchingFunction={dataFetchingFunction}
    >
      {(data) => (
        <SPUserCommitMetadataContext.Provider value={data}>
          {children}
        </SPUserCommitMetadataContext.Provider>
      )}
    </DataFetcherContainer>
  );
};
